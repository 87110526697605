import React from "react"
import { Link, graphql } from "gatsby"
import Form from "../components/footer"
import SEO from "../components/seo"
import rehypeReact from "rehype-react"
import Img from "gatsby-image"

import Layout from "../components/layout"

export default ({data}) => {
  const overview = data.diagram

  const renderAst = new rehypeReact({
    createElement: React.createElement
  }).Compiler

  return (
    <Layout>
      <SEO title="Overview" />
          
          <div className="head-title">
            <h2>{overview.frontmatter.title}</h2>
          </div>

          <div className="head-story-meta">
            <h5 className="timestamp">{overview.frontmatter.date}</h5>
          </div>

          <div className="head-image">
            <Img fluid={overview.frontmatter.image.childImageSharp.fluid} />
          </div>

          <div className="head-story-caption">
            <h5>{overview.frontmatter.caption}</h5>
          </div>

          <div className="head-body">
            <div>{renderAst(overview.htmlAst)}</div>
          </div>

          <div className="story-footer">
            <h3 className="hero-label">
              Diagram
            </h3>
            <p>
              Diagram is written, run and developed by me, <a href="https://blakehunsicker.com/">Blake Hunsicker</a>. Diagram is designed to help you explore the news and catch up on what you want to know.
            </p>
            <p>
              I have a lot of new features in the works. For occasional updates, consider sharing your email below.
            </p>
            <Form location="story" />
            <h5 className="copyright">Copyright 2019 Diagram</h5>
          </div>

      </Layout>
  )
}

export const query = graphql`
  query {
    diagram: markdownRemark(
      frontmatter: {
        title: {
          eq: "What is artificial intelligence?" 
        }
      }
    ) {
        frontmatter {
          title
          blurb
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          caption
          type
          epic
          date(formatString: "MMMM Do")
        }
        htmlAst
        fields {
          slug
        }
      }

  }
`